
import { defineComponent } from 'vue'
import ShowcasedRock from '@/components/ShowcasedRock.vue'
import { ROCK_TYPES } from '@/types/ROCK_TYPES'
import { makeRock } from '@/lib'
import { ROCK_DATA } from '@/constants'

export default defineComponent({
  name: 'Shared',
  components: { ShowcasedRock },
  computed: {
    validRock () {
      return !!ROCK_DATA[`${this.$route.query.type}` as ROCK_TYPES]
    },
    rock () {
      return makeRock({ type: `${this.$route.query.type}` as ROCK_TYPES })
    }
  },
  data () {
    return { dialog: true }
  }
})
